/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0px;
}

* {
  margin: 0;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.shadow_card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px !important;
}
.light_shadow_card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.shadow_card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* transform: scale(1.03); */
}

.margin-bottom {
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}
.padding-top {
  padding-top: 10px;
}

.cursor-grab {
  cursor: grab;
}

.image-sort {
  user-select: none;
  display: grid;
  grid-gap: 6px;
  grid-template-columns: auto auto auto auto;
  margin-top: 15px;
}

.track-horizontal {
  background-color: rgb(70, 70, 70);
  width: 100%;
  left: 0px;
  bottom: 0px;
  height: 10px !important;
}
.swiper-button-next {
  color: "#808080";
}
@media only screen and (max-width: 600px) {
  .image-sort {
    grid-template-columns: auto auto auto;
  }
}

.KDSF24 {
  display: none;
}

/* Background color change animation */

@keyframes backgroundColorPalette {
  0% {
    fill: #00e676;
  }
  /* 25% {
    fill: #64daa1;
  } */
  50% {
    fill: #62edaa;
  }
  /* 75% {
    fill: #96c9b0;
  } */
  100% {
    fill: #00e676;
  }
}

.animated_background_color {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

/* infinite rotate */

.infinite_rotate {
  animation: loading 3s linear infinite;
}
@keyframes loading {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/*  */

.example {
  position: relative;
  width: 20px;
  height: 20px;
}
.dot:before {
  content: " ";
  position: absolute;
  z-index: 2;
  left: 0;
  top: 4px;
  width: 10px;
  height: 10px;
  background-color: #1cae6f;
  border-radius: 50%;
}

.dot:after {
  content: " ";
  position: absolute;
  z-index: 1;
  width: 10px;
  height: 10px;
  background-color: #1cae6f;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
  -webkit-animation-name: "ripple"; /*动画属性名，也就是我们前面keyframes定义的动画名*/
  -webkit-animation-duration: 1s; /*动画持续时间*/
  -webkit-animation-timing-function: ease; /*动画频率，和transition-timing-function是一样的*/
  -webkit-animation-delay: 0s; /*动画延迟时间*/
  -webkit-animation-iteration-count: infinite; /*定义循环资料，infinite为无限次*/
  -webkit-animation-direction: normal; /*定义动画方式*/
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 5px;
    opcity: 75;
    width: 0;
    height: 0;
  }
  100% {
    left: -5px;
    top: -0px;
    opacity: 0;
    width: 20px;
    height: 20px;
  }
}

/* infinite scrolling animation */

.containerScroll {
  overflow: hidden;
  display: flex;
  /* height: 500px; */
  width: 1000px;
  margin: 0 auto;
  animation: slide 15s linear infinite;
}

/* .containerScroll div {
  height: 400px;
  min-width: 300px;
  margin-left: 100px;
} */
@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1500px, 0, 0); /* The image width */
  }
}

/* container for the speech bubble */
.speech-bubble.from-sender {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
  padding: 1em;
}

/* arrow pointing to the message sender */
.speech-bubble.from-sender:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  border: solid transparent;
  border-right-color: #ffffff;
  border-width: 0.8em;
  margin-top: -0.8em;
}

.speech-bubble.from-receiver {
  position: relative;
  background: #ffffff;
  border-radius: 0.4em;
  padding: 1em;
}

/* arrow pointing to the message sender */
.speech-bubble.from-receiver:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  border: solid transparent;
  border-left-color: #ffffff;
  border-width: 0.8em;
  margin-top: -0.8em;
}

button.reactour__nav-btn {
  background-color: blue;
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.blur-edge {
  /* border: 1px #d8d8d8 dashed; */
  font: 2em/1.6em Arial;
  position: relative;
}

.blur-edge:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 90%
  );
  width: 100%;
  height: 4em;
}

.btn--jump {
  animation: 0.4s jump ease infinite alternate;
}

@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  }
  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
}
.unclickable-box {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.unclickable-box-border {
  pointer-events: all;
  background: red;
  position: absolute;
}
.unclickable-box-border__top {
  width: 100%;
  /* height: $unclickable-box-border-size; */
  pointer-events: all;
}
.unclickable-box-border__bottom {
  bottom: 0;
  width: 100%;
  /* height: $unclickable-box-border-size; */
  pointer-events: all;
}
.unclickable-box-border__left {
  /* width: $unclickable-box-border-size; */
  height: 100%;
  pointer-events: all;
}
.unclickable-box-border__right {
  right: -10px;
  /* width: $unclickable-box-border-size * 2; */
  height: 100%;
  pointer-events: all;
}

.swiper-button-prev {
  color: red;
}

.swiper-button-next {
  color: #000;
}

/* call animation */

.content-center {
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #eee; */
}
/*  */

/* audio::-webkit-media-controls-panel {
  display: none !important;
} */

audio::-webkit-media-controls-timeline {
  display: none;
}
audio {
  width: 190px;
  height: 40px;
}

/* rocket */
@keyframes opacity-animation {
	0% {
		opacity: 0
	}
}

@keyframes pulse-animation {
	0% {
		transform: translate(-50%, -50%) scale(0);
		opacity: .8
	}
	70% {
		opacity: 0;
		transform: translate(-50%, -50%) scale(3)
	}
	to {
		transform: translate(-50%, -50%) scale(0);
		opacity: 0
	}
}

.pulse:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: #7dc87def;
    border-radius: 100%;
    opacity: .5;
    transform: translate(-50%,-50%) scale(3);
    animation: pulse-animation 3s infinite;
}

.pulse {
    position: relative;
    display: inline-block;
    vertical-align: 1px;
    width: 8px;
    height: 8px;
    margin: 0 6px;
    background: #ffdd40;
    color: transparent;
    border-radius: 100%;
    flex: 0 0 auto;
    animation: opacity-animation 1s linear;
  &.red {
    background: #e60000;
  }
  &.orange {
    background: #ff9900;
  }
  &.green {
    background: #00e600;
  }
}